import React from "react";

import Directory from '../../components/directory/directory.jsx';

const Home = () => {
  return (
    <Directory />
  )
}

export default Home;