import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/home.jsx";
import Navigation from "./links/navigation/navigation.jsx";
import SignPage from "./pages/signPage/signPage.jsx";
import Checkout from "./pages/checkout/checkout.jsx";
import Shop from "./pages/shop/shop.jsx";
import ShopCategory from "./pages/shopCategory/shopCategory.jsx";
import Success from "./pages/success/success.jsx";
import { useSelector } from "react-redux";

const App = () => {
  const currentUser = useSelector((state) => state.user.currentUser);

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        {currentUser ? (
          <>
            <Route path="shop" element={<Shop />} />
            <Route path="shop/:category" element={<ShopCategory />} />
            <Route path="checkout" element={<Checkout />} />
          </>
        ) : (
          <>
            <Route path="shop" element={<Home />} />
            <Route path="shop/*" element={<Home />} />
            <Route path="checkout" element={<Home />} />
          </>
        )}
        <Route path="sign-in" element={<SignPage />} />
        <Route path="success" element={<Success />} />
      </Route>
    </Routes>
  );
};

export default App;
