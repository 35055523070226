import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: {
    cartItems: [],
    itemsCount: 0,
    total: 0,
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updCart: (state, action) => {
      state.cart = action.payload;
    },
  },
});

export const { updCart } = cartSlice.actions;

export default cartSlice.reducer;
