import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../paymentForm/paymentForm";
import Button from "../button/button";

const Payment = () => {
  const { total } = useSelector((state) => state.cart.cart);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const paymentHandler = async () => {
    await fetch("http://localhost:4000/config", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
    await fetch("http://localhost:4000/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ total: total * 100 }),
    }).then(async (response) => {
      var { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    });
  };

  return (
    <>
      {total ? (
        <Button type={`button`} btnClass={``} onClick={paymentHandler}>
          Enable Payment Form
        </Button>
      ) : null}
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentForm />
        </Elements>
      )}
    </>
  );
};

export default Payment;
