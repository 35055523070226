import React, { useEffect } from "react";

import "./directory.scss";
import CategoryContainer from "../categoryContainer/categoryContainer.jsx";
import { useSelector, useDispatch } from "react-redux";
import { fetchDirectories } from "../../store/slices/directories/directoriesSlice";
import Spinner from "../spinner/spinner.jsx";

const Directory = () => {
  const directories = useSelector((state) => state.directories.directories);
  const isLoading = useSelector((state) => state.directories.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDirectories());
  }, [dispatch]);

  return (
    <div className="directories-container">
      {isLoading ? (
        <Spinner />
      ) : (
        directories.map(({ id, title, imageUrl }) => (
          <CategoryContainer key={id} title={title} image={imageUrl} />
        ))
      )}
    </div>
  );
};
export default Directory;
