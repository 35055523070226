import React from "react";
import styles from "./checkout.module.scss";
import CheckoutItem from "../../components/checkoutItem/checkoutItem";
import { useSelector } from "react-redux";
import {
  addItemToCart,
  removeItemFromCart,
  clearItemFromCart,
} from "../../store/slices/cart/cartFunctions.js";
import Payment from "../../components/payment/payment";

const Checkout = () => {
  const { cartItems, itemsCount, total } = useSelector(
    (state) => state.cart.cart
  );

  return (
    <div className={styles.checkoutContainer}>
      <div className={styles.checkoutHeader}>
        <div className={styles.headerBlock}>
          <span>Product</span>
        </div>
        <div className={styles.headerBlock}>
          <span>Description</span>
        </div>
        <div className={styles.headerBlock}>
          <span>Quantity</span>
        </div>
        <div className={styles.headerBlock}>
          <span>Price</span>
        </div>
        <div className={styles.headerBlock}>
          <span>Remove</span>
        </div>
      </div>
      {cartItems.map((cartItem) => {
        return (
          <CheckoutItem
            key={cartItem.id}
            className={styles.checkoutHeader}
            cartItems={cartItems}
            cartItem={cartItem}
            addHandler={addItemToCart}
            removeHandler={removeItemFromCart}
            clearHandler={clearItemFromCart}
          />
        );
      })}
      <div className={styles.footer}>
        <div className={styles.itemsCount}>Items: {itemsCount}</div>
        <div className={styles.total}>Total: ${total}</div>
      </div>
      <div style={{ padding: 20, width: 400 }}>
        <Payment />
      </div>
    </div>
  );
};
export default Checkout;
