import React from "react";

import styles from "./signPage.module.scss";

import SignUp from "../../components/signUp/signUp.jsx";
import SignIn from "../../components/signIn/signIn.jsx";

const SignPage = () => {
  return (
    <div className={styles.containerWrapper}>
      <SignIn />
      <SignUp />
    </div>
  );
};

export default SignPage;
