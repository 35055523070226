import { PRODUCTS } from "../../../shop-data";

import {
  addCollectionAndDocuments,
  getCategoriesAndDocuments,
} from "../../../utils/firebase/firebase.js";

//Promise is resolved here and stored in myProducts
export const myProducts = (async () =>
  await new Promise((success, error) => {
    addCollectionAndDocuments("hats", "categories", PRODUCTS);
    getCategoriesAndDocuments("categories")
      ? success(getCategoriesAndDocuments("categories"))
      : error([]);
  }))();
