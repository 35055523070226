import React from "react";
import styles from "./productCard.module.scss";
import Button from "../button/button.jsx";

import { useSelector, useDispatch } from "react-redux";

import { addItemToCart } from "../../store/slices/cart/cartFunctions.js";

const ProductCard = ({ product }) => {
  const { cartItems } = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  const { name, imageUrl, price } = product;
  
  const addProductToCart = () => {
    dispatch({
      payload: addItemToCart(cartItems, product),
      type: "cart/updCart",
    });
  };

  return (
    <div className={styles.productCardContainer}>
      <img src={imageUrl} alt={`${name}`} />
      <div className={styles.footer}>
        <span className={styles.name}>{name}</span>
        <span className={styles.price}>{price}</span>
      </div>
      <Button type={`button`} btnClass={``} onClick={addProductToCart}>
        Add to Cart
      </Button>
    </div>
  );
};

export default ProductCard;
