import React, { useEffect } from "react";
import styles from "./success.module.scss";
import { useDispatch } from "react-redux";

const Success = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const clearCart = () => {
      dispatch({
        payload: {
          cartItems: [],
          itemsCount: 0,
          total: 0,
        },
        type: "cart/updCart",
      });
    };
    clearCart();
  }, [dispatch]);

  return (
    <div className={styles.successContainer}>
      <h1>Payment Successful !!!!</h1>
    </div>
  );
};

export default Success;
