import DIRECTORIES from "../../../components/directory/directory.json";

import {
  addCollectionAndDocuments,
  getCategoriesAndDocuments,
} from "../../../utils/firebase/firebase.js";

//Promise is resolved here and stored in myDirectories
export const myDirectories = (async () =>
  await new Promise((success, error) => {
    getCategoriesAndDocuments("directories");
    addCollectionAndDocuments("hats", "directories", DIRECTORIES)
      ? success(getCategoriesAndDocuments("directories"))
      : error([]);
  }))();
