import React from "react";
import styles from "./checkoutItem.module.scss";
import { useDispatch } from "react-redux";

const CheckoutItem = ({
  cartItems,
  cartItem,
  addHandler,
  removeHandler,
  clearHandler,
}) => {
  const { name, quantity, price, imageUrl } = cartItem;
  const dispatch = useDispatch();

  const addItemHandler = () => {
    dispatch({
      payload: addHandler(cartItems, cartItem),
      type: "cart/updCart",
    });
  };
  const removeItemHandler = () => {
    dispatch({
      payload: removeHandler(cartItems, cartItem),
      type: "cart/updCart",
    });
  };
  const clearItemHandler = () => {
    dispatch({
      payload: clearHandler(cartItems, cartItem),
      type: "cart/updCart",
    });
  };

  return (
    <div className={styles.checkoutItemContainer}>
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt={`${name}`} />
      </div>
      <span className={styles.name}> {name} </span>
      <span className={styles.quantity}>
        <div className={styles.arrow} onClick={removeItemHandler}>
          &#10094;
        </div>
        <span className={styles.value}>{quantity}</span>
        <div className={styles.arrow} onClick={addItemHandler}>
          &#10095;
        </div>
      </span>
      <span className={styles.price}> {price}</span>
      <div className={styles.removeButton} onClick={clearItemHandler}>
        &#10005;
      </div>
    </div>
  );
};

export default CheckoutItem;
