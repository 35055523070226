import React from "react";
import { ReactComponent as ShoppingIcon } from "../../assets/shopping-bag.svg";
import styles from "./cartIcon.module.scss";

import { useSelector } from "react-redux";

const CartIcon = () => {
  const { itemsCount } = useSelector((state) => state.cart.cart);
  return (
    <div className={styles.cartIconContainer}>
      <ShoppingIcon className={styles.shoppingIcon} />
      <span className={styles.itemCount}>{itemsCount}</span>
    </div>
  );
};

export default CartIcon;
