import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user/userSlice.js";
import directoriesReducer from "./slices/directories/directoriesSlice.js";
import productsReducer from "./slices/products/productsSlice.js";
import cartReducer from "./slices/cart/cartSlice.js";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [ "directories", "products" ],
};

const reducer = combineReducers({
  user: userReducer,
  directories: directoriesReducer,
  products: productsReducer,
  cart: cartReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
